'use client';

import { useState } from 'react';

import { Locale } from '@core/Entities/Locale/Locale.entity';
import { Tabs } from '@gds/Tabs/Tabs';

import { SearchForm } from './SearchForm';

import styles from './EvSearchBanner.module.css';

type Props = {
  customFilterParams?: Record<string, string>;
  strings: Record<string, string>;
  locale: Locale;
};

export const EvSearchBannerForm = ({ strings, locale, customFilterParams }: Props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [monthlyFrom, setMonthlyFrom] = useState(100);
  const [monthlyTo, setMonthlyTo] = useState(1000);

  const [totalFrom, setTotalFrom] = useState(5_000);
  const [totalTo, setTotalTo] = useState(80_000);

  const [range, setRange] = useState(300);

  const tabButtons = [
    {
      label: strings.monthlyPayments,
      dataTestId: 'ev-search-banner-mensualities',
    },
    {
      label: strings.totalPrice,
      dataTestId: 'ev-search-banner-prix-total',
    },
  ];

  const tabContents = [
    <SearchForm
      key="monthly"
      strings={strings}
      from={monthlyFrom}
      setFrom={setMonthlyFrom}
      to={monthlyTo}
      setTo={setMonthlyTo}
      range={range}
      setRange={setRange}
      isMonthly={true}
      locale={locale}
      id="ev-search-form-mensualities"
      dataTestId="ev-search-form-mensualities"
      customFilterParams={customFilterParams}
    />,
    <SearchForm
      key="total"
      strings={strings}
      from={totalFrom}
      setFrom={setTotalFrom}
      to={totalTo}
      setTo={setTotalTo}
      range={range}
      setRange={setRange}
      isMonthly={false}
      locale={locale}
      id="ev-search-form-prix-total"
      dataTestId="ev-search-form-prix-total"
      customFilterParams={customFilterParams}
    />,
  ];

  return (
    <div className={styles.searchSection} data-test-id="ev-search-banner-form">
      <Tabs
        activeIndex={activeTabIndex}
        buttons={tabButtons}
        onChange={setActiveTabIndex}
        tabContents={tabContents}
        idPrefix="ev-search"
        className={styles.tabs}
        dataTestId="ev-search-tabs"
      />
    </div>
  );
};
